import React from 'react';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';

function HomePage() {
  return (
    <>
      <Navbar />
      <div className="flex justify-center items-center h-screen bg-gray-200">
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-4xl font-bold mb-4">Learn a new language today!</h1>
          <p className="text-gray-600 mb-8">Our app makes it easy to learn a new language at your own pace.</p>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          <Link to="https://app.fluencydaily.com">Get started</Link>
          </button>
        </div>
      </div>
      <div className="bg-gray-800 text-gray py-8">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-4">Why choose our app?</h2>
          <p className="mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <p className="mb-4">
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p>
          <p className="mb-4">
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          </p>
        </div>
      </div>
    </>
  );
}

export default HomePage;
