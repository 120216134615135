import './App.css';
import { BrowserRouter as Router, Routes, Route , Navigate} from 'react-router-dom';
/* views */
import HomePage from './views/HomePage';

function App() {
  return (
    <div className="App">
      <Router >
      
      <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      </Router>
    </div>
  );
}

export default App;
