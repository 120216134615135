import React from 'react';

function Navbar() {
  return (
    <nav className="bg-red-500 px-4 py-2">
      <div className="container mx-auto flex justify-between items-center">
        <a href="/" className="text-white font-bold text-xl">Fluency Daily</a>
        <ul className="flex">
          <li className="ml-4">
            <a href="/" className="text-white hover:text-gray-200">Home</a>
          </li>
          <li className="ml-4">
            <a href="/about" className="text-white hover:text-gray-200">About</a>
          </li>
          <li className="ml-4">
            <a href="/contact" className="text-white hover:text-gray-200">Contact</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;